import { graphql } from 'gatsby';
import React from 'react';
import {
  Section,
  Grid,
  H2WithBorder,
  Box,
  FlexCol,
  TextBoxWithButton,
  TextBoxWithLinkAndSquareColor,
  Visible,
  Hidden,
} from '../../../components';
import { fontWeights, space } from '../../../theme';
import Slider from 'react-slick';

const BOX_FIRST_COLOR =
  'linear-gradient(180deg, #5FB3DF -50.98%, rgba(95, 179, 223, 0) 176.47%)';
const BOX_SECOND_COLOR =
  'linear-gradient(179.69deg, #008E8E 0.27%, rgba(0, 142, 142, 0) 178.71%)';
const BOX_THIRD_COLOR =
  'linear-gradient(179.58deg, #33D8FF 0.36%, rgba(51, 216, 255, 0) 133.71%)';

const getSquareBoxColorByIndex = (index: number): string => {
  if (index === 0) return BOX_FIRST_COLOR;
  if (index === 1) return BOX_SECOND_COLOR;
  if (index === 2) return BOX_THIRD_COLOR;
  return BOX_FIRST_COLOR;
};

export const LayoutBoxSectionWithLinksAndButton: React.FC<{
  title: string;
  text: {
    text: string;
    childMarkdownRemark: {
      html: string;
    };
  };
  linkBoxTitle: string;
  linkBoxButtonTitle: string;
  linkBoxButtonUrl: string;
  boxes: {
    title: string;
    text: {
      text: string;
      childMarkdownRemark: {
        html: string;
      };
    };
    linkTitle: string;
    linkUrl: string;
  }[];
}> = ({
  title,
  text,
  boxes,
  linkBoxTitle,
  linkBoxButtonTitle,
  linkBoxButtonUrl,
}) => {
  const settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true,
    slidesToShow: 1,
  };

  return (
    <Section py={space['8xl']}>
      <Grid.Container>
        <Grid.Row align="center">
          <Grid.Col md={6}>
            <Box mb={text && space.xl}>
              <H2WithBorder
                fontWeight={fontWeights.semiBold}
                title={title}
                textHtml={
                  text &&
                  text.childMarkdownRemark &&
                  text.childMarkdownRemark.html
                }
              />
            </Box>
          </Grid.Col>
        </Grid.Row>
        <Hidden xs sm>
          <Grid.Row>
            {boxes &&
              boxes.map((box, index) => (
                <Grid.Col key={index} md={6} lg={5}>
                  <FlexCol
                    my={space.md}
                    position="relative"
                    left={{
                      lg: index === 2 ? '35%' : '',
                    }}
                    minHeight={{ xxl: 200, lg: 220, md: 280 }}
                  >
                    <TextBoxWithLinkAndSquareColor
                      squareBoxColor={getSquareBoxColorByIndex(index)}
                      {...box}
                      text={
                        box.text &&
                        box.text.childMarkdownRemark &&
                        box.text.childMarkdownRemark.html
                      }
                      key={index}
                    />
                  </FlexCol>
                </Grid.Col>
              ))}
            <Grid.Col xs={12} md={6} lg={5}>
              <FlexCol
                my={space.md}
                position="relative"
                left={{
                  lg: '35%',
                }}
                minHeight={{ xxl: 200, lg: 220, md: 280 }}
              >
                <TextBoxWithButton
                  linkBoxTitle={linkBoxTitle}
                  linkBoxButtonTitle={linkBoxButtonTitle}
                  linkBoxButtonUrl={linkBoxButtonUrl}
                />
              </FlexCol>
            </Grid.Col>
          </Grid.Row>
        </Hidden>

        <Visible xs sm>
          <Box mx={-15}>
            <Slider {...settings}>
              {boxes &&
                boxes.map((box, index) => (
                  <FlexCol my={space.md} key={index}>
                    <Box mx={space.sm}>
                      <TextBoxWithLinkAndSquareColor
                        squareBoxColor={getSquareBoxColorByIndex(index)}
                        {...box}
                        text={
                          box.text &&
                          box.text.childMarkdownRemark &&
                          box.text.childMarkdownRemark.html
                        }
                        key={index}
                      />
                    </Box>
                  </FlexCol>
                ))}
            </Slider>
          </Box>
          <FlexCol my={space.md}>
            <TextBoxWithButton
              linkBoxTitle={linkBoxTitle}
              linkBoxButtonTitle={linkBoxButtonTitle}
              linkBoxButtonUrl={linkBoxButtonUrl}
            />
          </FlexCol>
        </Visible>
      </Grid.Container>
    </Section>
  );
};

export const contentfulLayoutBoxSectionWithLinksAndButtonFragment = graphql`
  fragment ContentfulLayoutBoxSectionWithLinksAndButton on ContentfulLayoutBoxSectionWithLinksAndButton {
    title
    text {
      text
      childMarkdownRemark {
        html
      }
    }
    linkBoxTitle
    linkBoxButtonTitle
    linkBoxButtonUrl
    boxes {
      title
      text {
        text
        childMarkdownRemark {
          html
        }
      }
      linkTitle
      linkUrl
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`;
