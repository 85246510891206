import React, { useEffect, useState } from 'react';
import {
  Box,
  H4,
  Icons,
  FlexRow,
  Text,
  Separator,
  LinkBox,
} from '../../../components';
import { colors, fontWeights, space } from '../../../theme';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const ToggleIcon = styled(Box)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid ${colors.black};
  position: relative;
  cursor: pointer;
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    background: ${colors.black};
    border-radius: 2px;
    transition: opacity 0.3s ease;
  }
  &:before {
    width: 2px;
    left: 50%;
    margin: 0 0 0 -1px;
    top: 5px;
    bottom: 5px;
    opacity: ${(props) => (props.isOpen ? 0 : 1)};
  }
  &:after {
    height: 2px;
    top: 50%;
    margin: -1px 0 0 0;
    left: 5px;
    right: 5px;
  }
`;

export const Toggles: React.FC<{
  toggles: {
    icon: {
      icon: string;
    };
    text: string;
    title: string;
  }[];
}> = ({ toggles }) => {
  const [openToggle, setOpenToggle] = useState<{
    icon: {
      icon: string;
    };
    text: string;
    title: string;
  } | null>(null);

  useEffect(() => {
    if (toggles[0]) {
      setOpenToggle(toggles[0]);
    }
  }, []);

  const variants = {
    open: { opacity: 1, height: 'auto' },
    closed: { opacity: 0, height: 0 },
  };

  return (
    <AnimatePresence initial={false}>
      {toggles &&
        toggles.map((toggle, index) => {
          if (!toggle.icon || !toggle.icon.icon) return null;
          const IconComponent = Icons[toggle.icon.icon];
          if (!IconComponent) return null;

          return (
            <Box key={index}>
              <Box style={{ overflow: 'hidden' }}>
                <LinkBox onClick={() => setOpenToggle(toggle)}>
                  <FlexRow>
                    <Box mr={space.xl}>
                      <IconComponent size={24} />
                    </Box>
                    <H4
                      fontWeight={fontWeights.semiBold}
                      color={colors.darkestGrey}
                    >
                      {toggle.title}
                    </H4>
                    <Box marginLeft="auto">
                      <ToggleIcon isOpen={toggle === openToggle} />
                    </Box>
                  </FlexRow>
                </LinkBox>
                <Box>
                  <motion.div
                    animate={toggle === openToggle ? 'open' : 'closed'}
                    transition={{ duration: 0.4 }}
                    variants={variants}
                    initial={false}
                  >
                    <Box ml={space.xxxxl} mr={{ lg: space.xl }}>
                      <Text color={colors.darkestGrey}>{toggle.text}</Text>
                    </Box>
                  </motion.div>
                </Box>
              </Box>
              {index + 1 < toggles.length && (
                <Separator.Light mt={space.md} mb={space.md} />
              )}
            </Box>
          );
        })}
    </AnimatePresence>
  );
};
