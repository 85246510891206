import { graphql } from 'gatsby';
import React from 'react';
import { Section, Grid, Box, H1, H3 } from '../components';
import { space, fontWeights } from '../theme';

export const TitleWithSubtitle: React.FC<{
  title: string;
  subtitle: string;
}> = ({ title, subtitle }) => {
  return (
    <Section pt={space['8xl']} pb={space.xxl}>
      <Grid.Container>
        <Grid.Row align="center">
          <Grid.Col xs={12}>
            <Box>
              <Box mb={space.xl}>
                <H1 fontWeight={fontWeights.extraBold}>{title}</H1>
              </Box>
              <Box>
                <H3 fontWeight={fontWeights.semiBold}>{subtitle}</H3>
              </Box>
            </Box>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Section>
  );
};

export const contentfulTitleWithSubtitleFragment = graphql`
  fragment ContentfulTitleWithSubtitle on ContentfulTitleWithSubtitle {
    title
    subtitle
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`;
