import { graphql } from 'gatsby';
import React from 'react';

import {
  Section,
  Grid,
  H2WithBorder,
  Image,
  Box,
  FlexCol,
} from '../../../components';
import { space, fontWeights } from '../../../theme';
import { TextIcon } from './TextIcon';

const IMAGE_WIDTH = 45;
const TEXT_MAX_WIDTH = 650;

export const LayoutFeatureSectionWithText: React.FC<{
  title: string;
  text: { text: string };
  topImage: any;
  features: {
    title: string;
    text: { text: string };
    icon: {
      icon: string;
    };
  }[];
}> = ({ title, text, topImage, features }) => {
  return (
    <Section py={space['8xl']}>
      <Grid.Container>
        <Grid.Row align="center">
          <Grid.Col md={6}>
            <Box maxWidth={TEXT_MAX_WIDTH} mb={{ xs: space.lg }}>
              <Box pb={space.lg} width={IMAGE_WIDTH}>
                <Image image={topImage} />
              </Box>
              <H2WithBorder
                fontWeight={fontWeights.semiBold}
                title={title}
                textHtml={text && text.text}
              />
            </Box>
          </Grid.Col>
          <Grid.Col md={6}>
            <Grid.Row>
              {features.map((feature, index) => (
                <Grid.Col key={index} lg={6}>
                  <FlexCol
                    mb={{ xs: space.xl, lg: 0 }}
                    mx={{ xxl: space.lg }}
                    mt={{
                      lg: index === 0 || index === 2 ? '-15%' : '15%',
                    }}
                    minHeight={{
                      xl: '320px',
                      lg: '350px',
                    }}
                  >
                    <TextIcon {...feature} key={index} />
                  </FlexCol>
                </Grid.Col>
              ))}
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Section>
  );
};

export const contentfulLayoutFeatureSectionWithTextFragment = graphql`
  fragment ContentfulLayoutFeatureSectionWithText on ContentfulLayoutFeatureSectionWithText {
    title
    features {
      ...ContentfulLayoutFeatureSectionWithTextTextIcon
    }
    topImage {
      file {
        url
      }
      title
      fluid(maxWidth: 2400, quality: 100) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
    text {
      text
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`;
