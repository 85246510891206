import { motion } from 'framer-motion';
import { graphql, StaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Section,
  Grid,
  H3WithBorder,
  Box,
  H4,
  Text,
  LinkBox,
  Separator,
  FlexRow,
} from '../../../components';
import { space, fontWeights, colors } from '../../../theme';

const ToggleIcon = styled(Box)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid ${colors.black};
  position: relative;
  cursor: pointer;
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    background: ${colors.black};
    border-radius: 2px;
    transition: opacity 0.3s ease;
  }
  &:before {
    width: 2px;
    left: 50%;
    margin: 0 0 0 -1px;
    top: 5px;
    bottom: 5px;
    opacity: ${(props) => (props.isOpen ? 0 : 1)};
  }
  &:after {
    height: 2px;
    top: 50%;
    margin: -1px 0 0 0;
    left: 5px;
    right: 5px;
  }
`;

export const LayoutFAQSection: React.FC<{
  title: string;
  articles: { contentfulid: string }[];
}> = ({ title, articles }) => {
  const articleIds =
    articles && articles.map((article) => article.contentfulid);

  const [filteredArticles, setFilteredArticles] = useState([]);
  const [openArticle, setOpenArticle] = useState(null);

  const variants = {
    open: { opacity: 1, height: 'auto', visibility: 'visible' },
    closed: { opacity: 0, height: 0, visibility: 'hidden' },
  };

  return (
    <Section py={space['8xl']}>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col xs={12}>
            <Box mb={{ xs: 0, md: space.xxl }}>
              <H3WithBorder textAlign="center" title={title} textHtml="" />
            </Box>
          </Grid.Col>
          <Grid.Col xs={12}>
            <StaticQuery
              query={graphql`
                query {
                  allFreshdeskArticle {
                    nodes {
                      id
                      title
                      description
                    }
                  }
                }
              `}
              render={(data) => {
                const articles = data.allFreshdeskArticle.nodes.filter((n) => {
                  return articleIds && articleIds.indexOf(n.id) !== -1;
                });

                useEffect(() => {
                  setFilteredArticles(articles);
                  if (articles && articles[0]) setOpenArticle(articles[0]);
                }, []);

                return (
                  <Box maxWidth={900} margin="0 auto">
                    {filteredArticles &&
                      filteredArticles.map((article: any, index: number) => (
                        <Box key={index} my={space.lg}>
                          <Box marginBottom={space.md}>
                            <LinkBox onClick={() => setOpenArticle(article)}>
                              <FlexRow
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <H4 fontWeight={fontWeights.semiBold}>
                                  {article.title}
                                </H4>
                                <ToggleIcon isOpen={article === openArticle} />
                              </FlexRow>
                            </LinkBox>
                          </Box>
                          <motion.div
                            animate={
                              article === openArticle ? 'open' : 'closed'
                            }
                            transition={{ duration: 0.4 }}
                            variants={variants}
                            initial={false}
                          >
                            <Box>
                              <Text>{article.description}</Text>
                            </Box>
                          </motion.div>
                          {index + 1 < filteredArticles.length && (
                            <Separator.Light mt={space.md} mb={space.md} />
                          )}
                        </Box>
                      ))}
                  </Box>
                );
              }}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Section>
  );
};

export const contentfulLayoutFaqSectionFragment = graphql`
  fragment ContentfulLayoutFaqSection on ContentfulLayoutFaqSection {
    title
    articles {
      contentfulid
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`;
