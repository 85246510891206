import { graphql } from 'gatsby';
import React from 'react';
import {
  Section,
  Grid,
  Text,
  Box,
  H3WithBorder,
  H4,
  Separator,
  FlexCol,
  TextBoxWithLinkAndSquareColor,
  Hidden,
} from '../../../components';
import { colors, fontWeights, space } from '../../../theme';

const BOX_FIRST_COLOR =
  'linear-gradient(180deg, #5FB3DF -50.98%, rgba(95, 179, 223, 0) 176.47%)';
const BOX_SECOND_COLOR =
  'linear-gradient(179.58deg, #33D8FF 0.36%, rgba(51, 216, 255, 0) 133.71%)';

const BoxColumn: React.FC<{
  boxes: {
    boxText: string;
    title: string;
    linkTitle: string;
    linkUrl: string;
  }[];
}> = ({ boxes }) => (
  <Box>
    {boxes &&
      boxes.map((box, index) => (
        <FlexCol
          maxWidth={{ lg: '75%' }}
          my={space.md}
          position="relative"
          left={{
            lg: index === 1 ? '20%' : '',
          }}
          minHeight={{ xxl: 200, lg: 220, md: 280 }}
        >
          <TextBoxWithLinkAndSquareColor
            squareBoxColor={index === 0 ? BOX_FIRST_COLOR : BOX_SECOND_COLOR}
            {...box}
            text={box.boxText}
            key={index}
          />
        </FlexCol>
      ))}
  </Box>
);

function TextColumn({ title, features, text }) {
  return (
    <Box>
      <Box mb={text && space.xl}>
        <H3WithBorder title={title} textHtml={text} />
      </Box>
      {features.map((feature, index) => (
        <Box mb={space.lg} key={index}>
          <Box mb={space.md}>
            <H4 color={colors.darkestGrey} fontWeight={fontWeights.semiBold}>
              {feature.title}
            </H4>
          </Box>
          <Box mb={space.lg}>
            <Text color={colors.darkestGrey}>{feature.featureText}</Text>
          </Box>
          <Separator.Light />
        </Box>
      ))}
    </Box>
  );
}

export const LayoutFeatureSectionWithBoxes: React.FC<{
  title: string;
  text: {
    text: string;
    childMarkdownRemark: {
      html: string;
    };
  };
  features: {
    featureText: string;
    title: string;
  }[];
  boxes: {
    boxText: string;
    title: string;
    linkTitle: string;
    linkUrl: string;
  }[];
}> = ({ title, boxes, features, text }) => {
  return (
    <Section py={space['8xl']} backgroundColor={colors.secondaryDark005}>
      <Grid.Container>
        <Grid.Row align="center">
          <Grid.Col md={5}>
            <Hidden xs sm>
              <BoxColumn boxes={boxes} />
            </Hidden>
          </Grid.Col>
          <Grid.Col md={7}>
            <TextColumn
              text={
                text &&
                text.childMarkdownRemark &&
                text.childMarkdownRemark.html
              }
              features={features}
              title={title}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Section>
  );
};

export const contentfulLayoutFeatureSectionWithBoxesFragment = graphql`
  fragment ContentfulLayoutFeatureSectionWithBoxes on ContentfulLayoutFeatureSectionWithBoxes {
    title
    text {
      text
      childMarkdownRemark {
        html
      }
    }
    features {
      title
      featureText: text
    }
    boxes {
      title
      boxText: text
      linkTitle
      linkUrl
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`;
