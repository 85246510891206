import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import {
  Section,
  Grid,
  H2WithBorder,
  Box,
  H4,
  Separator,
  H3,
  FlexCol,
  H5,
  FlexRow,
  Text,
  Icons,
  Hidden,
  Visible,
} from '../../../components';
import { space, fontWeights, colors } from '../../../theme';
import Slider from 'react-slick';

const BackgroundBox = styled(FlexCol)`
  background: ${(props) => (props.whiteBg ? '#fff' : '#f9fafc')};
  border-radius: 8px;
  padding: ${space.md} 0;
`;

const Feature: React.FC<{
  feature: {
    text: string;
    perText: string;
    hasFeature: boolean;
    sys: {
      contentType: {
        sys: {
          id: string;
        };
      };
    };
  };
  textAlign?: 'center' | 'right';
}> = ({ feature, textAlign }) => {
  const id = feature.sys.contentType.sys.id;

  if (id === 'layoutPricingSectionPriceModelTextFeature') {
    return (
      <Box height="25px">
        <H4
          textAlign={textAlign ? textAlign : 'center'}
          color={colors.secondaryDark}
          fontWeight={fontWeights.semiBold}
        >
          {feature.text && feature.text}
        </H4>
      </Box>
    );
  }

  if (id === 'layoutPricingSectionPriceModelPer') {
    return (
      <FlexRow
        height="25px"
        justifyContent="center"
        alignItems="center"
        position="relative"
      >
        <Box>
          <H4 color={colors.secondaryDark} fontWeight={fontWeights.semiBold}>
            {feature.text}
          </H4>
        </Box>
        <Box position="absolute" right={0}>
          <Text color={colors.darkestGrey}>{feature.perText}</Text>
        </Box>
      </FlexRow>
    );
  }

  if (id === 'layoutPricingSectionPriceModelYesno') {
    if (feature.hasFeature)
      return (
        <FlexCol
          height="25px"
          alignItems={textAlign === 'right' ? 'flex-end' : 'center'}
        >
          <Icons.CheckCircle />
        </FlexCol>
      );
    return (
      <FlexCol
        height="25px"
        alignItems={textAlign === 'right' ? 'flex-end' : 'center'}
      >
        <Icons.Stash />
      </FlexCol>
    );
  }

  return null;
};

const PriceHeading: React.FC<{
  title: string;
  subtitle: string;
  perText: string;
}> = ({ title, subtitle, perText }) => (
  <Box height="150px">
    <Box mb={space.md}>
      <H3
        textAlign="center"
        fontWeight={fontWeights.semiBold}
        color={colors.black}
      >
        {title}
      </H3>
    </Box>
    <Box>
      <H3
        textAlign="center"
        fontWeight={fontWeights.semiBold}
        color={colors.primary}
      >
        {subtitle}
      </H3>
    </Box>
    {perText && (
      <Box pb={space.xs}>
        <H5 textAlign="center" color={colors.darkestGrey}>
          {perText}
        </H5>
      </Box>
    )}
  </Box>
);

const TitleGroups: React.FC<{
  titleGroups: {
    title: string;
    titles: {
      title: string;
    }[];
  }[];
}> = ({ titleGroups }) => {
  return (
    <Box>
      {titleGroups &&
        titleGroups.map((titleGroup, index: number) => (
          <Box key={index} py={space.sm}>
            {titleGroup.title && (
              <Box py={space.sm} mx={space.xxl}>
                <Box height="25px">
                  <H4 color={colors.darkGrey} fontWeight={fontWeights.semiBold}>
                    {titleGroup.title}
                  </H4>
                </Box>
              </Box>
            )}
            {titleGroup.titles &&
              titleGroup.titles.map((title, index: number) => (
                <Box mx={space.xxl} py={space.sm} key={index}>
                  <Box height="25px">
                    <H4 color={colors.secondaryDark}>{title.title}</H4>
                  </Box>
                </Box>
              ))}
            {index + 1 < titleGroups.length && (
              <Box ml={space.xxl} pt={space.md}>
                <Separator.Secondary />
              </Box>
            )}
          </Box>
        ))}
    </Box>
  );
};

const FeatureGroups: React.FC<{
  featureGroups: {
    features: {
      text: string;
      perText: string;
      hasFeature: boolean;
      sys: {
        contentType: {
          sys: {
            id: string;
          };
        };
      };
    }[];
  }[];
  textAlign?: 'center' | 'right';
}> = ({ featureGroups, textAlign }) => (
  <Box>
    {featureGroups &&
      featureGroups.map((featureGroup, index: number) => (
        <Box key={index} py={space.sm}>
          {featureGroup.features &&
            featureGroup.features.map((feature, index: number) => (
              <Box
                py={space.sm}
                key={index}
                mx={{ xs: space.md, xxl: space.xxl }}
              >
                <Feature textAlign={textAlign} feature={feature} />
              </Box>
            ))}
          {index + 1 < featureGroups.length && (
            <Box pt={space.md}>
              <Separator.Secondary />
            </Box>
          )}
        </Box>
      ))}
  </Box>
);

export const LayoutPricingSection: React.FC<{
  title: string;
  titleGroups: {
    title: string;
    titles: {
      title: string;
    }[];
  }[];
  priceModels: {
    title: string;
    subtitle: string;
    perText: string;
    featureGroups: {
      features: {
        text: string;
        perText: string;
        hasFeature: boolean;
        sys: {
          contentType: {
            sys: {
              id: string;
            };
          };
        };
      }[];
    }[];
  }[];
}> = ({ title, titleGroups, priceModels }) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
  };

  return (
    <Section py={space['8xl']}>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col xs={12}>
            <Box mb={{ xs: space['5xl'], md: space.xxxl }}>
              <H2WithBorder
                fontWeight={fontWeights.semiBold}
                textHtml=""
                title={title}
              />
            </Box>
          </Grid.Col>
        </Grid.Row>
        <Visible xs sm>
          <Grid.Row>
            <Grid.Col xs={12}>
              <Slider {...sliderSettings}>
                {priceModels &&
                  priceModels.map((priceModel, index: number) => (
                    <Box key={index} mt={space.xxl}>
                      <BackgroundBox>
                        <PriceHeading
                          title={priceModel.title}
                          subtitle={priceModel.subtitle}
                          perText={priceModel.perText}
                        />
                        <FlexRow>
                          <FlexCol width="50%" flexGrow={0} flexShrink={0}>
                            <TitleGroups titleGroups={titleGroups} />
                          </FlexCol>
                          <FlexCol
                            width="50%"
                            flexGrow={0}
                            flexShrink={0}
                            paddingRight={space.lg}
                          >
                            <FeatureGroups
                              textAlign="right"
                              featureGroups={priceModel.featureGroups}
                            />
                          </FlexCol>
                        </FlexRow>
                      </BackgroundBox>
                    </Box>
                  ))}
              </Slider>
            </Grid.Col>
          </Grid.Row>
        </Visible>
        <Hidden xs sm>
          <Grid.Row>
            <Grid.Col md={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Box height="150px"></Box>
              <BackgroundBox>
                <TitleGroups titleGroups={titleGroups} />
              </BackgroundBox>
            </Grid.Col>
            {priceModels &&
              priceModels.map((priceModel, index: number) => (
                <Grid.Col
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                  md={2}
                  key={index}
                >
                  <PriceHeading
                    title={priceModel.title}
                    subtitle={priceModel.subtitle}
                    perText={priceModel.perText}
                  />
                  <BackgroundBox whiteBg={index % 2 === 0}>
                    <FeatureGroups featureGroups={priceModel.featureGroups} />
                  </BackgroundBox>
                </Grid.Col>
              ))}
          </Grid.Row>
        </Hidden>
      </Grid.Container>
    </Section>
  );
};

export const contentfulLayoutPricingSectionFragment = graphql`
  fragment ContentfulLayoutPricingSection on ContentfulLayoutPricingSection {
    title
    titleGroups {
      title
      titles {
        title
      }
    }
    priceModels {
      title
      subtitle
      perText
      featureGroups {
        features {
          ... on ContentfulLayoutPricingSectionPriceModelPer {
            text
            perText
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulLayoutPricingSectionPriceModelText {
            text
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulLayoutPricingSectionPriceModelYesNo {
            hasFeature
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`;
