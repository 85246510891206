import { graphql } from 'gatsby';
import React from 'react';
import {
  Section,
  Grid,
  H1,
  Box,
  H5,
  LinkPrimaryButton,
  Image,
  Visible,
} from '../../components';
import { space, fontWeights, colors } from '../../theme';

const TextCol: React.FC<{
  title: string;
  text: {
    text: string;
    childMarkdownRemark: {
      html: string;
    };
  };
  buttonLink: string;
  buttonText: string;
}> = ({ title, text, buttonLink, buttonText }) => (
  <Box mb={{ xs: space.xl }}>
    <Box mb={space.lg}>
      <H1 fontWeight={fontWeights.extraBold}>{title}</H1>
    </Box>
    <Box>
      <H5>
        {text && text.childMarkdownRemark && text.childMarkdownRemark.html && (
          <div
            dangerouslySetInnerHTML={{
              __html: text.childMarkdownRemark.html,
            }}
          />
        )}
      </H5>
    </Box>
    {buttonLink && buttonText && (
      <Box mt={space.xl}>
        <LinkPrimaryButton to={buttonLink} title={buttonText}>
          {buttonText}
        </LinkPrimaryButton>
      </Box>
    )}
  </Box>
);

export const LayoutHero: React.FC<{
  title: string;
  text: {
    text: string;
    childMarkdownRemark: {
      html: string;
    };
  };
  buttonText: string;
  buttonLink: string;
  image: any;
  id: string;
  greyBackground: boolean;
}> = ({ title, text, buttonText, buttonLink, image, greyBackground }) => {
  return (
    <Section
      py={space.xl}
      backgroundColor={greyBackground ? colors.secondaryDark005 : ''}
    >
      <Grid.Container>
        <Visible md lg xl xxl>
          <Grid.Row align="center">
            <Grid.Col md={6}>
              <TextCol
                title={title}
                text={text}
                buttonText={buttonText}
                buttonLink={buttonLink}
              />
            </Grid.Col>
            <Grid.Col md={6}>
              <Image image={image} />
            </Grid.Col>
          </Grid.Row>
        </Visible>
        <Visible xs sm>
          <Grid.Col>
            <Image image={image} />
          </Grid.Col>
          <Grid.Col>
            <TextCol
              title={title}
              text={text}
              buttonText={buttonText}
              buttonLink={buttonLink}
            />
          </Grid.Col>
        </Visible>
      </Grid.Container>
    </Section>
  );
};

export const contentfulLayoutHeroFragment = graphql`
  fragment ContentfulLayoutHero on ContentfulLayoutHero {
    buttonLink
    buttonText
    greyBackground
    text {
      text
      childMarkdownRemark {
        html
      }
    }
    title
    image {
      file {
        url
      }
      fluid(maxWidth: 2400, quality: 100) {
        ...GatsbyContentfulFluid_noBase64
      }
      title
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`;
