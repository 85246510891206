import { graphql } from 'gatsby';
import React from 'react';
import {
  Section,
  Grid,
  H3WithBorder,
  Image,
  Box,
  Hidden,
} from '../../../components';
import { space, colors } from '../../../theme';

import { Toggles } from './Toggles';

const ImageColumn: React.FC<{ image: any }> = ({ image }) => (
  <Hidden xs sm>
    <Image image={image} />
  </Hidden>
);

const TextColumn: React.FC<{
  title: string;
  textHtml: string;
  toggles: {
    icon: {
      icon: string;
    };
    text: string;
    title: string;
  }[];
}> = ({ title, textHtml, toggles }) => (
  <Box>
    <H3WithBorder title={title} textHtml={textHtml} />
    <Box mt={space.xxxl}>
      <Toggles toggles={toggles} />
    </Box>
  </Box>
);

export const LayoutSectionWithImageAndToggles: React.FC<{
  title: string;
  text: {
    text: string;
    childMarkdownRemark: {
      html: string;
    };
  };
  image: any;
  imageRight: boolean;
  greyBackground: boolean;
  toggles: {
    icon: {
      icon: string;
    };
    text: string;
    title: string;
  }[];
}> = ({ title, text, image, imageRight, greyBackground, toggles }) => {
  return (
    <Section
      py={space['8xl']}
      backgroundColor={greyBackground ? colors.secondaryDark005 : ''}
    >
      <Grid.Container>
        {imageRight ? (
          <Grid.Row align="center">
            <Grid.Col md={7} lg={7}>
              <TextColumn
                toggles={toggles}
                title={title}
                textHtml={
                  text &&
                  text.childMarkdownRemark &&
                  text.childMarkdownRemark.html
                }
              />
            </Grid.Col>
            <Hidden xs sm md>
              <Grid.Col xs={0} lg={1}></Grid.Col>
            </Hidden>
            <Grid.Col md={5} lg={4}>
              <Box mt={{ xs: space.xl, md: 0 }}>
                <ImageColumn image={image} />
              </Box>
            </Grid.Col>
          </Grid.Row>
        ) : (
          <Grid.Row align="center">
            <Grid.Col md={5} lg={4}>
              <Box mb={{ xs: space.xl, md: 0 }}>
                <ImageColumn image={image} />
              </Box>
            </Grid.Col>
            <Hidden xs sm md>
              <Grid.Col xs={0} lg={1}></Grid.Col>
            </Hidden>
            <Grid.Col md={7}>
              <TextColumn
                toggles={toggles}
                title={title}
                textHtml={
                  text &&
                  text.childMarkdownRemark &&
                  text.childMarkdownRemark.html
                }
              />
            </Grid.Col>
          </Grid.Row>
        )}
      </Grid.Container>
    </Section>
  );
};

export const contentfulLayoutSectionWithImageAndTogglesFragment = graphql`
  fragment ContentfulLayoutSectionWithImageAndToggles on ContentfulLayoutSectionWithImageAndToggles {
    title
    greyBackground
    imageRight
    toggles {
      title
      text
      icon {
        icon
      }
    }
    image {
      file {
        url
      }
      title
      fluid(maxWidth: 2400, quality: 100) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
    text {
      text
      childMarkdownRemark {
        html
      }
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`;
