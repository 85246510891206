import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import {
  Section,
  Grid,
  Image,
  Box,
  H3WithBorder,
  Hidden,
} from '../../../components';
import { space, colors } from '../../../theme';

import bgImage from '../../../images/rectangle-bg.png';

const StyledRectangleBGBox = styled(Box)`
  background-image: ${(props) =>
    props.withRectangleBg ? `url(${bgImage})` : ''};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
`;

const ImageColumn: React.FC<{ image: any; withRectangleBg: boolean }> = ({
  image,
  withRectangleBg,
}) => (
  <StyledRectangleBGBox withRectangleBg={withRectangleBg}>
    <Image image={image} />
  </StyledRectangleBGBox>
);

const StyledBorderBox = styled(Box)`
  border-top: ${(props) =>
    props.withBorder ? `1px solid ${colors.lightGrey}` : ''};
  border-bottom: ${(props) =>
    props.withBorder ? `1px solid ${colors.lightGrey}` : ''};
  padding: ${(props) => (props.withBorder ? `${space.xl} 0` : '')};
`;

export const LayoutImageWithTextAndHeading: React.FC<{
  title: string;
  text: {
    text: string;
    childMarkdownRemark: {
      html: string;
    };
  };
  image: any;
  imageRight: boolean;
  withBorder: boolean;
  withRectangleBg: boolean;
  greyBackground: boolean;
}> = ({
  title,
  image,
  text,
  imageRight,
  greyBackground,
  withBorder,
  withRectangleBg,
}) => {
  return (
    <Section
      py={space['8xl']}
      backgroundColor={greyBackground ? colors.secondaryDark005 : ''}
    >
      <Grid.Container>
        <StyledBorderBox withBorder={withBorder}>
          {imageRight ? (
            <Grid.Row align="center">
              <Grid.Col md={6}>
                <H3WithBorder
                  title={title}
                  textHtml={
                    text &&
                    text.childMarkdownRemark &&
                    text.childMarkdownRemark.html
                  }
                />
              </Grid.Col>
              <Grid.Col md={6}>
                <Hidden xs sm>
                  <Box mx={{ md: space.md }}>
                    <ImageColumn
                      withRectangleBg={withRectangleBg}
                      image={image}
                    />
                  </Box>
                </Hidden>
              </Grid.Col>
            </Grid.Row>
          ) : (
            <Grid.Row align="center">
              <Grid.Col md={6}>
                <Hidden xs sm>
                  <Box mx={{ md: space.md }}>
                    <ImageColumn
                      withRectangleBg={withRectangleBg}
                      image={image}
                    />
                  </Box>
                </Hidden>
              </Grid.Col>
              <Grid.Col md={6}>
                <H3WithBorder
                  title={title}
                  textHtml={
                    text &&
                    text.childMarkdownRemark &&
                    text.childMarkdownRemark.html
                  }
                />
              </Grid.Col>
            </Grid.Row>
          )}
        </StyledBorderBox>
      </Grid.Container>
    </Section>
  );
};

export const contentfulLayoutImageWithTextAndHeadingFragment = graphql`
  fragment ContentfulLayoutImageWithTextAndHeading on ContentfulLayoutImageWithTextAndHeading {
    title
    text {
      text
      childMarkdownRemark {
        html
      }
    }
    image {
      file {
        url
      }
      title
      fluid(maxWidth: 2400, quality: 100) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
    imageRight
    withBorder
    withRectangleBg
    greyBackground
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`;
