import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import LinesEllipsis from 'react-lines-ellipsis';

import { Box, H4, Text, FlexCol, Icons } from '../../../components';
import { colors, fontWeights, space } from '../../../theme';

const StyledBox = styled(FlexCol)`
  justify-content: center;
  border-radius: 4px;
  background-color: ${colors.white};
  box-shadow: 0px 6.2382px 15.5955px rgba(168, 168, 168, 0.25);
  border-radius: 3.1191px;
  border: 1px solid ${colors.grey};
  padding: ${space.lg};
  flex: 1;
  text-align: center;
`;

const ICON_BG_SIZE = '64px';

const StyledIcon = styled(Box)`
  border-radius: 4px;
  background-color: ${colors.primary};
  padding: ${space.sm};
  display: inline-block;
  height: ${ICON_BG_SIZE};
  width: ${ICON_BG_SIZE};
`;

export const TextIcon: React.FC<{
  title: string;
  text: { text: string };
  icon: {
    icon: string;
  };
}> = ({ title, text, icon }) => {
  if (!icon || !icon.icon) return null;
  const IconComponent = Icons[icon.icon];
  if (!IconComponent) return null;

  return (
    <StyledBox>
      <Box mb={space.lg}>
        <StyledIcon>
          <IconComponent color="white" size={48} />
        </StyledIcon>
      </Box>
      <Box mb={space.lg}>
        <H4 fontWeight={fontWeights.semiBold} color={colors.darkestGrey}>
          {title}
        </H4>
      </Box>
      <Box>
        <Text color={colors.darkestGrey}>
          <LinesEllipsis
            text={text && text.text}
            maxLine="5"
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        </Text>
      </Box>
    </StyledBox>
  );
};

export const contentfulLayoutFeatureSectionWithTextTextIconFragment = graphql`
  fragment ContentfulLayoutFeatureSectionWithTextTextIcon on ContentfulLayoutFeatureSectionWithTextTextIcon {
    title
    text {
      text
    }
    icon {
      icon
    }
  }
`;
