import { graphql } from 'gatsby';
import React from 'react';
import { Section, Grid, Box, Text } from '../components';
import { space } from '../theme';

export const FreeText: React.FC<{
  text: {
    childContentfulRichText: {
      html: string;
    };
  };
}> = ({ text }) => {
  return (
    <Section py={space.xxxxl}>
      <Grid.Container>
        <Grid.Row align="center">
          <Grid.Col xs={12}>
            <Box className="free-text">
              <Text>
                <div
                  dangerouslySetInnerHTML={{
                    __html: text.childContentfulRichText.html,
                  }}
                ></div>
              </Text>
            </Box>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Section>
  );
};

export const contentfulFreeTextFragment = graphql`
  fragment ContentfulFreeText on ContentfulFreeText {
    text {
      childContentfulRichText {
        html
      }
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`;
