import { graphql } from 'gatsby';
import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import validator from 'email-validator';

import {
  Section,
  Grid,
  H2,
  Box,
  Text,
  WhiteButton,
  FlexCol,
  Input,
  FlexRow,
} from '../../../components';
import { space, colors } from '../../../theme';
import { useIntl } from '../../../hooks';
import { navigate } from '../../../utils';

const validEmail = (email) => {
  const lowerCaseEmail = email ? String(email).toLowerCase() : '';

  return validator.validate(lowerCaseEmail);
};

const StyledBoxWithBorder = styled(Box)`
  width: 110px;
  height: 2px;
  background-color: ${colors.white};
`;

const ErrorLabel: React.FC<{ error: string | null }> = ({ error }) => {
  if (!error) return null;
  return (
    <Box width="100%" pt={space.xs} position="absolute" bottom="-25px">
      <Text color={colors.primaryDark} textAlign="center">
        {error}
      </Text>
    </Box>
  );
};

export const LayoutNewsletterSection: React.FC<{
  title: string;
  emailInputPlaceholder: string;
  firstNameInputPlaceholder: string;
  lastNameInputPlaceholder: string;
  text: {
    text: string;
    childMarkdownRemark: {
      html: string;
    };
  };
  buttonText: string;
  backgroundColor: string;
}> = ({
  title,
  emailInputPlaceholder,
  firstNameInputPlaceholder,
  lastNameInputPlaceholder,
  text,
  buttonText,
  backgroundColor,
}) => {
  const intl = useIntl();

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const [emailError, setEmailError] = useState<null | string>(null);
  const [firstNameError, setFirstNameError] = useState<null | string>(null);
  const [lastNameError, setLastNameError] = useState<null | string>(null);

  const validateEmail = (val: string) => {
    if (val.trim() === '') {
      setEmailError(intl.formatMessage({ id: 'formErrors.required' }));
    } else if (!validEmail(val.trim())) {
      setEmailError(intl.formatMessage({ id: 'formErrors.email' }));
    } else {
      setEmailError(null);
    }
  };

  const validateFirstName = (val: string) => {
    if (val.trim() === '') {
      setFirstNameError(intl.formatMessage({ id: 'formErrors.required' }));
    } else {
      setFirstNameError(null);
    }
  };

  const validateLastName = (val: string) => {
    if (val.trim() === '') {
      setLastNameError(intl.formatMessage({ id: 'formErrors.required' }));
    } else {
      setLastNameError(null);
    }
  };

  const submit = () => {
    setSubmitted(true);
    validateEmail(email);
    validateFirstName(firstName);
    validateLastName(lastName);

    if (
      email &&
      email.trim() !== '' &&
      validEmail(email) &&
      firstName &&
      firstName.trim() !== '' &&
      lastName &&
      lastName.trim() !== ''
    ) {
      const newLead = {
        Email: email,
        'First name': firstName,
        'Last name': lastName,
      };
      const identifier = email;
      freshsales.identify(identifier, newLead);
      navigate('/thank-you');
    }
  };

  return (
    <Section>
      <Section
        py={space.xxxxl}
        backgroundColor={colors[backgroundColor] || colors.primary}
      >
        <Grid.Container>
          <Grid.Row align="center">
            <Grid.Col>
              <FlexCol alignItems="center" maxWidth="550px" margin="0 auto">
                <Box mb={space.md}>
                  <H2 color={colors.white} textAlign="center">
                    {title}
                  </H2>
                </Box>
                <StyledBoxWithBorder mb={space.lg} />
                <Box mb={space.xxxxl}>
                  <Text textAlign="center" color={colors.white}>
                    {text &&
                      text.childMarkdownRemark &&
                      text.childMarkdownRemark.html && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: text.childMarkdownRemark.html,
                          }}
                        />
                      )}
                  </Text>
                </Box>
                <FlexCol
                  mb={space.lg}
                  minWidth="70%"
                  marginLeft="auto"
                  marginRight="auto"
                >
                  <FlexRow justifyContent="space-between">
                    <FlexCol
                      width="48%"
                      flexGrow={0}
                      flexShrink={0}
                      position="relative"
                    >
                      <Input
                        type="text"
                        value={firstName}
                        onChange={(e) => {
                          setFirstName(e.target.value);
                          if (submitted) validateFirstName(e.target.value);
                        }}
                        placeholder={firstNameInputPlaceholder}
                        error={firstNameError}
                      />
                      <ErrorLabel error={firstNameError} />
                    </FlexCol>
                    <FlexCol
                      width="48%"
                      flexGrow={0}
                      flexShrink={0}
                      position="relative"
                    >
                      <Input
                        type="text"
                        value={lastName}
                        onChange={(e) => {
                          setLastName(e.target.value);
                          if (submitted) validateLastName(e.target.value);
                        }}
                        placeholder={lastNameInputPlaceholder}
                        error={lastNameError}
                      />
                      <ErrorLabel error={lastNameError} />
                    </FlexCol>
                  </FlexRow>
                  <Box mt={space.lg} position="relative">
                    <Input
                      type="text"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        if (submitted) validateEmail(e.target.value);
                      }}
                      placeholder={emailInputPlaceholder}
                      error={emailError}
                    />
                    <ErrorLabel error={emailError} />
                  </Box>
                </FlexCol>
                <Box mt={space.md}>
                  <WhiteButton onClick={submit} title={buttonText}>
                    {buttonText}
                  </WhiteButton>
                </Box>
              </FlexCol>
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      </Section>
    </Section>
  );
};

export const contentfulLayoutNewsletterSectionFragment = graphql`
  fragment ContentfulLayoutNewsletterSection on ContentfulLayoutNewsletterSection {
    title
    text {
      text
      childMarkdownRemark {
        html
      }
    }
    emailInputPlaceholder
    firstNameInputPlaceholder
    lastNameInputPlaceholder
    backgroundColor
    buttonText
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`;
