import { graphql } from 'gatsby';
import React from 'react';
import {
  Section,
  Grid,
  H3WithBorder,
  Box,
  FlexCol,
  TextBoxWithIcon,
} from '../../../components';
import { space } from '../../../theme';

export const LayoutBoxSectionWithIcons: React.FC<{
  title: string;
  boxes: {
    boxText: string;
    title: string;
    icon: {
      icon: string;
    };
  }[];
}> = ({ title, boxes }) => {
  return (
    <Section py={space['8xl']}>
      <Grid.Container>
        <Grid.Row align="center">
          <Grid.Col>
            <Box>
              <H3WithBorder textAlign="center" title={title} textHtml="" />
            </Box>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          {boxes && boxes.map((box, index) => (
            <Grid.Col md={6} lg={5}>
              <FlexCol
                my={space.md}
                position="relative"
                left={{
                  lg: index === 2 || index === 3 ? '35%' : '',
                }}
                minHeight={{ xxl: 200, lg: 220, md: 280 }}
              >
                <TextBoxWithIcon
                  title={box.title}
                  text={box.boxText}
                  icon={box.icon && box.icon.icon}
                  key={index}
                />
              </FlexCol>
            </Grid.Col>
          ))}
        </Grid.Row>
      </Grid.Container>
    </Section>
  );
};

export const contentfulLayoutBoxSectionWithIconsFragment = graphql`
  fragment ContentfulLayoutBoxSectionWithIcons on ContentfulLayoutBoxSectionWithIcons {
    title
    boxes {
      title
      boxText: text
      icon {
        icon
      }
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`;
