import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import {
  Section,
  Grid,
  H2,
  Box,
  Text,
  LinkWhiteButton,
  FlexCol,
} from '../../../components';
import { space, colors, fontWeights } from '../../../theme';

const StyledBoxWithBorder = styled(Box)`
  width: 200px;
  height: 2px;
  background-color: ${colors.white};
`;

export const LayoutCTASection: React.FC<{
  title: string;
  ctaSectionText: string;
  buttonText: string;
  buttonLink: string;
  fullwidth: boolean;
}> = ({ title, ctaSectionText, buttonText, buttonLink, fullwidth }) => {
  return (
    <Section>
      <Section
        py={space.xxxxl}
        backgroundColor={fullwidth ? colors.primary : ''}
      >
        <Grid.Container>
          <Box
            backgroundColor={!fullwidth ? colors.primary : ''}
            p={!fullwidth ? space.xxxl : ''}
            style={!fullwidth ? { borderRadius: '8px' } : {}}
          >
            <Grid.Row align="center">
              <Grid.Col md={8}>
                <Box mb={space.sm}>
                  <H2
                    textAlign={{ xs: 'center', md: 'left' }}
                    fontWeight={fontWeights.extraBold}
                    color={colors.white}
                  >
                    {title}
                  </H2>
                </Box>
                <StyledBoxWithBorder
                  marginLeft={{ xs: 'auto', md: 0 }}
                  marginRight={{ xs: 'auto', md: 0 }}
                  mb={space.lg}
                />
                <Box>
                  <Text
                    textAlign={{ xs: 'center', md: 'left' }}
                    color={colors.white}
                  >
                    {ctaSectionText}
                  </Text>
                </Box>
              </Grid.Col>
              <Grid.Col md={4}>
                <FlexCol
                  pt={{ xs: space.xl, md: 0 }}
                  alignItems={{ xs: 'center', md: 'flex-end' }}
                >
                  <LinkWhiteButton title={buttonText} to={buttonLink}>
                    {buttonText}
                  </LinkWhiteButton>
                </FlexCol>
              </Grid.Col>
            </Grid.Row>
          </Box>
        </Grid.Container>
      </Section>
    </Section>
  );
};

export const contentfulLayoutCtaSectionFragment = graphql`
  fragment ContentfulLayoutCtaSection on ContentfulLayoutCtaSection {
    title
    ctaSectionText: text
    buttonText
    buttonLink
    fullwidth
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`;
