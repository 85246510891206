import { graphql } from 'gatsby';
import React from 'react';
import {
  Section,
  Grid,
  Image,
  Box,
  H3WithBorder,
  H4,
  Text,
  Separator,
  Hidden,
  Visible,
} from '../../../components';
import { space, fontWeights, colors } from '../../../theme';

const ImageColumn: React.FC<{ image: any }> = ({ image }) => (
  <Image image={image} />
);

const TextColumn: React.FC<{
  title: string;
  text: string;
  features: {
    featureText: string;
    title: string;
  }[];
}> = ({ title, features, text }) => (
  <Box>
    <Box mb={text && space.xl}>
      <H3WithBorder title={title} textHtml={text} />
    </Box>
    <Box>
      {features.map((feature, index) => (
        <Box mb={space.lg} key={index}>
          <Box mb={space.md}>
            <H4 color={colors.darkestGrey} fontWeight={fontWeights.semiBold}>
              {feature.title}
            </H4>
          </Box>
          <Box mb={space.lg}>
            <Text color={colors.darkestGrey}>{feature.featureText}</Text>
          </Box>
          <Separator.Light />
        </Box>
      ))}
    </Box>
  </Box>
);

export const LayoutFeatureSectionWithImage: React.FC<{
  title: string;
  text: {
    text: string;
    childMarkdownRemark: {
      html: string;
    };
  };
  image: any;
  features: {
    featureText: string;
    title: string;
  }[];
  imageRight: boolean;
  greyBackground: boolean;
}> = ({ title, image, features, imageRight, greyBackground, text }) => {
  return (
    <Section
      py={space['8xl']}
      backgroundColor={greyBackground ? colors.secondaryDark005 : ''}
    >
      <Grid.Container>
        {imageRight ? (
          <Grid.Row align="center">
            <Grid.Col md={7}>
              <Visible xs sm>
                <Box mb={space.xxl}>
                  <ImageColumn image={image} />
                </Box>
              </Visible>
              <TextColumn
                text={
                  text &&
                  text.childMarkdownRemark &&
                  text.childMarkdownRemark.html
                }
                features={features}
                title={title}
              />
            </Grid.Col>
            <Grid.Col md={5}>
              <Hidden xs sm>
                <Box ml={{ lg: space.xl }}>
                  <ImageColumn image={image} />
                </Box>
              </Hidden>
            </Grid.Col>
          </Grid.Row>
        ) : (
          <Grid.Row align="center">
            <Grid.Col md={5}>
              <Box mb={{ xs: space.xxl, md: 0 }} mr={{ lg: space.xl }}>
                <ImageColumn image={image} />
              </Box>
            </Grid.Col>
            <Grid.Col md={7}>
              <TextColumn
                text={
                  text &&
                  text.childMarkdownRemark &&
                  text.childMarkdownRemark.html
                }
                features={features}
                title={title}
              />
            </Grid.Col>
          </Grid.Row>
        )}
      </Grid.Container>
    </Section>
  );
};

export const contentfulLayoutFeatureSectionWithImageFragment = graphql`
  fragment ContentfulLayoutFeatureSectionWithImage on ContentfulLayoutFeatureSectionWithImage {
    title
    text {
      text
      childMarkdownRemark {
        html
      }
    }
    greyBackground
    features {
      title
      featureText: text
    }
    image {
      file {
        url
      }
      title
      fluid(maxWidth: 2400, quality: 100) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
    imageRight
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`;
