import { graphql } from 'gatsby';
import React, { useState } from 'react';
import {
  Section,
  Grid,
  H2WithBorder,
  Box,
  Text,
  FlexRow,
  H3,
  LinkBox,
  Image,
  Hidden,
  Visible,
  FlexCol,
} from '../../../components';
import {
  space,
  fontWeights,
  colors,
  fontSizes,
  lineHeights,
} from '../../../theme';
import styled from 'styled-components';
import Slider from 'react-slick';

const StyledTab = styled(Box)`
  background: ${colors.lightestGrey};
  padding: ${space.md} 0;
  border-radius: 2px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  width: 160px;
  text-align: center;
`;

const StyledActiveTab = styled(Box)`
  background: ${colors.white};
  padding: ${space.md} 0;
  border-radius: 2px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  width: 160px;
  text-align: center;
`;

const StyledCard = styled(FlexCol)`
  border: 1px solid ${colors.grey};
  border-radius: 3px;
  padding: ${space.lg};
  box-shadow: 0px 6.2382px 15.5955px rgba(168, 168, 168, 0.25);
  text-align: center;
  background-color: ${colors.white};
`;

const StyledSliderFlexCol = styled(FlexCol)`
  display: flex !important;
`;

const StyledImageBox = styled(Box)`
  width: 118px;
  height: 118px;
  margin: 0 auto;
  border-radius: 100%;
  overflow: hidden;
  background: ${colors.lightGrey};
`;

export const CompanyOurMission: React.FC<{
  title: string;
  ourMissionText: string;
  tabOneTitle: string;
  tabTwoTitle: string;
  leaderships: {
    title: string;
    subtitle: string;
    leadershipAdvisorText: {
      text: string;
      childMarkdownRemark: {
        html: string;
      };
    };
    image: any;
  }[];
  advisors: {
    title: string;
    subtitle: string;
    leadershipAdvisorText: {
      text: string;
      childMarkdownRemark: {
        html: string;
      };
    };
    image: any;
  }[];
}> = ({
  title,
  ourMissionText,
  tabOneTitle,
  tabTwoTitle,
  leaderships,
  advisors,
}) => {
  const [leadershipTabOpen, setLeadershipTabOpen] = useState<boolean>(true);
  const [advisorTabOpen, setAdvisorTabOpen] = useState<boolean>(false);
  const [colsToShow, setColsToShow] = useState<
    {
      title: string;
      subtitle: string;
      leadershipAdvisorText: {
        text: string;
        childMarkdownRemark: {
          html: string;
        };
      };
      image: any;
    }[]
  >(leaderships);

  const settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true,
    slidesToShow: 1,
  };

  return (
    <Section py={space.xxxxl}>
      <Grid.Container>
        <Grid.Row align="center">
          <Grid.Col>
            <Box maxWidth="600px" margin="0 auto">
              <H2WithBorder
                textAlign="center"
                title={title}
                textHtml={ourMissionText}
              />
            </Box>
            <FlexRow mt={space.xl} mb={space.xl} justifyContent="center">
              <LinkBox
                style={{ zIndex: leadershipTabOpen ? 9 : 0 }}
                onClick={() => {
                  setAdvisorTabOpen(false);
                  setLeadershipTabOpen(true);
                  setColsToShow(leaderships);
                }}
              >
                {leadershipTabOpen ? (
                  <StyledActiveTab>
                    <Text
                      color={colors.primary}
                      fontWeight={fontWeights.semiBold}
                    >
                      {tabOneTitle}
                    </Text>
                  </StyledActiveTab>
                ) : (
                  <StyledTab>
                    <Text
                      color={colors.darkestGrey05}
                      fontWeight={fontWeights.semiBold}
                    >
                      {tabOneTitle}
                    </Text>
                  </StyledTab>
                )}
              </LinkBox>
              <LinkBox
                style={{ zIndex: advisorTabOpen ? 9 : 0 }}
                onClick={() => {
                  setLeadershipTabOpen(false);
                  setAdvisorTabOpen(true);
                  setColsToShow(advisors);
                }}
              >
                {advisorTabOpen ? (
                  <StyledActiveTab>
                    <Text
                      color={colors.primary}
                      fontWeight={fontWeights.semiBold}
                    >
                      {tabTwoTitle}
                    </Text>
                  </StyledActiveTab>
                ) : (
                  <StyledTab>
                    <Text
                      color={colors.darkestGrey05}
                      fontWeight={fontWeights.semiBold}
                    >
                      {tabTwoTitle}
                    </Text>
                  </StyledTab>
                )}
              </LinkBox>
            </FlexRow>
          </Grid.Col>
        </Grid.Row>
        <Hidden xs sm>
          <Grid.Row>
            {colsToShow.map((tab, index) => (
              <Grid.Col md={6} lg={4} key={index} style={{ display: 'flex' }}>
                <StyledCard
                  my={space.lg}
                  maxWidth={{ xxl: 350 }}
                  marginLeft={{ xxl: 'auto' }}
                  marginRight={{ xxl: 'auto' }}
                  style={{ width: '100%' }}
                >
                  <Box mb={space.md}>
                    <StyledImageBox>
                      <Image image={tab.image} />
                    </StyledImageBox>
                  </Box>
                  <Box mb={space.sm}>
                    <H3 fontWeight={fontWeights.semiBold}>{tab.title}</H3>
                  </Box>
                  <Box mb={space.md}>
                    <Text
                      color={colors.darkGrey}
                      fontSize={fontSizes.tiny}
                      lineHeight={lineHeights.tiny}
                    >
                      {tab.subtitle}
                    </Text>
                  </Box>
                  <Box>
                    <Text color={colors.darkGrey}>
                      {tab.leadershipAdvisorText &&
                        tab.leadershipAdvisorText.childMarkdownRemark &&
                        tab.leadershipAdvisorText.childMarkdownRemark.html && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                tab.leadershipAdvisorText.childMarkdownRemark
                                  .html,
                            }}
                          />
                        )}
                    </Text>
                  </Box>
                </StyledCard>
              </Grid.Col>
            ))}
          </Grid.Row>
        </Hidden>
        <Visible xs sm>
          <Box mx={-15}>
            <Slider {...settings}>
              {colsToShow.map((tab, index) => (
                <StyledSliderFlexCol key={index}>
                  <StyledCard
                    my={space.lg}
                    mx={space.sm}
                    style={{ height: '100%' }}
                  >
                    <Box mb={space.md}>
                      <StyledImageBox>
                        <Image image={tab.image} />
                      </StyledImageBox>
                    </Box>
                    <Box mb={space.sm}>
                      <H3 fontWeight={fontWeights.semiBold}>{tab.title}</H3>
                    </Box>
                    <Box mb={space.md}>
                      <Text
                        color={colors.darkGrey}
                        fontSize={fontSizes.tiny}
                        lineHeight={lineHeights.tiny}
                      >
                        {tab.subtitle}
                      </Text>
                    </Box>
                    <Box>
                      <Text color={colors.darkGrey}>
                        {tab.leadershipAdvisorText.text}
                      </Text>
                    </Box>
                  </StyledCard>
                </StyledSliderFlexCol>
              ))}
            </Slider>
          </Box>
        </Visible>
      </Grid.Container>
    </Section>
  );
};

export const contentfulCompanyOurMissionFragment = graphql`
  fragment ContentfulCompanyOurMission on ContentfulCompanyOurMission {
    title
    ourMissionText: text
    tabOneTitle
    tabTwoTitle
    leaderships {
      title
      subtitle
      leadershipAdvisorText: text {
        text
        childMarkdownRemark {
          html
        }
      }
      image {
        file {
          url
        }
        title
        fluid(maxWidth: 2400, quality: 100) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
    advisors {
      title
      subtitle
      leadershipAdvisorText: text {
        text
        childMarkdownRemark {
          html
        }
      }
      image {
        file {
          url
        }
        title
        fluid(maxWidth: 2400, quality: 100) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`;
