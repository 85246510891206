import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import {
  Section,
  Grid,
  H2WithBorder,
  Image,
  Box,
  FlexRow,
  LinkBox,
  FlexCol,
  H3,
  Text,
  Visible,
} from '../../../components';
import { space, colors, fontWeights } from '../../../theme';
import styled from 'styled-components';
import ArrowLeft from '../../../icons/BigArrowLeft.svg';
import ArrowRight from '../../../icons/BigArrowRight.svg';

const StyledSelectTestimonial = styled(Box)`
  height: 32px;
  background-color: ${colors.lightestGrey};
`;

const StyledSelectSelectedTestimonial = styled(Box)`
  height: 32px;
  background-color: ${colors.lightGrey};
`;

const GreyBoxes: React.FC<{
  testimonials: any[];
  activeTestimonial: any;
  onClick: (testimonial: any, index: number) => void;
}> = ({ testimonials, activeTestimonial, onClick }) => (
  <FlexRow mt={space.lg}>
    {testimonials.map((testimonial, index) => (
      <FlexCol
        key={index}
        flex={1}
        mr={index + 1 < testimonials.length ? space.md : ''}
      >
        <LinkBox onClick={() => onClick(testimonial, index)} key={index}>
          {activeTestimonial && activeTestimonial === testimonial ? (
            <StyledSelectSelectedTestimonial />
          ) : (
            <StyledSelectTestimonial />
          )}
        </LinkBox>
      </FlexCol>
    ))}
  </FlexRow>
);

export const LayoutTestimonialSection: React.FC<{
  title: string;
  testimonials: {
    text: {
      text: string;
      childMarkdownRemark: {
        html: string;
      };
    };
    title: string;
    image: any;
  }[];
}> = ({ title, testimonials }) => {
  const [activeTestimonial, setActiveTestimonial] = useState<{
    text: {
      text: string;
      childMarkdownRemark: {
        html: string;
      };
    };
    title: string;
    image: any;
  } | null>(null);
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    if (testimonials && testimonials[0]) {
      setActiveTestimonial(testimonials[0]);
      setIndex(0);
    }
  }, []);

  useEffect(() => {
    const id = setTimeout(() => {
      selectNextTestimonial();
    }, 5000);

    return () => clearTimeout(id);
  }, [index]);

  const selectPrevTestimonial = () => {
    if (index - 1 >= 0) {
      setActiveTestimonial(testimonials[index - 1]);
      setIndex(index - 1);
    } else {
      setActiveTestimonial(testimonials[testimonials.length - 1]);
      setIndex(testimonials.length - 1);
    }
  };

  const selectNextTestimonial = () => {
    if (index + 1 < testimonials.length) {
      setActiveTestimonial(testimonials[index + 1]);
      setIndex(index + 1);
    } else {
      setActiveTestimonial(testimonials[0]);
      setIndex(0);
    }
  };

  return (
    <Section py={space['8xl']}>
      <Grid.Container>
        <Grid.Row align="center">
          <Grid.Col>
            <H2WithBorder title={title} textHtml="" />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row align="center">
          <Grid.Col md={6}>
            <Box maxWidth={{ xl: '80%' }} mb={{ xs: space.xl, md: 0 }}>
              <Box
                style={{ overflow: 'hidden' }}
                height={{
                  xs: 350,
                  sm: 350,
                  md: 230,
                  lg: 300,
                  xl: 300,
                  xxl: 400,
                }}
              >
                <Image image={activeTestimonial?.image} />
              </Box>
              <Visible md lg xl xxl>
                <GreyBoxes
                  testimonials={testimonials}
                  activeTestimonial={activeTestimonial}
                  onClick={(testimonial: any, index: number) => {
                    setIndex(index);
                    setActiveTestimonial(testimonial);
                  }}
                />
              </Visible>
            </Box>
          </Grid.Col>
          <Grid.Col md={6}>
            <Box>
              <Box mb={space.lg2}>
                <H3 fontWeight={fontWeights.extraBold}>
                  {activeTestimonial && activeTestimonial.title}
                </H3>
              </Box>
              <Box>
                <Text color={colors.darkestGrey}>
                  {activeTestimonial &&
                    activeTestimonial.text &&
                    activeTestimonial.text.childMarkdownRemark && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            activeTestimonial.text.childMarkdownRemark.html,
                        }}
                      />
                    )}
                </Text>
              </Box>
            </Box>
            <Box mt={space.xl}>
              <FlexRow>
                <LinkBox onClick={selectPrevTestimonial} pr={space.xl}>
                  <ArrowLeft />
                </LinkBox>
                <LinkBox onClick={selectNextTestimonial}>
                  <ArrowRight />
                </LinkBox>
              </FlexRow>
            </Box>
            <Visible xs sm>
              <GreyBoxes
                testimonials={testimonials}
                activeTestimonial={activeTestimonial}
                onClick={(testimonial: any, index: number) => {
                  setIndex(index);
                  setActiveTestimonial(testimonial);
                }}
              />
            </Visible>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Section>
  );
};

export const contentfulLayoutTestimonialSectionFragment = graphql`
  fragment ContentfulLayoutTestimonialSection on ContentfulLayoutTestimonialSection {
    title
    testimonials {
      title
      text {
        text
        childMarkdownRemark {
          html
        }
      }
      image {
        file {
          url
        }
        title
        fluid(maxWidth: 2400, quality: 100) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`;
