import { LayoutHero } from './Layout/Hero';
import { LayoutFeatureSectionWithText } from './Layout/FeatureSectionWithText';
import { LayoutBoxSectionWithLinksAndButton } from './Layout/BoxSectionWithLinksAndButton';
import { LayoutSectionWithImageAndToggles } from './Layout/SectionWithImageAndToggles';
import { LayoutTestimonialSection } from './Layout/TestimonialSection';
import { LayoutCTASection } from './Layout/CTASection';
import { LayoutFAQSection } from './Layout/FAQSection';
import { LayoutFeatureSectionWithImage } from './Layout/FeatureSectionWithImage';
import { LayoutFeatureSectionWithBoxes } from './Layout/FeatureSectionWithBoxes';
import { LayoutBoxSectionWithIcons } from './Layout/BoxSectionWithIcons';
import { LayoutImageWithTextAndHeading } from './Layout/ImageWithTextAndHeading';
import { LayoutNewsletterSection } from './Layout/NewsletterSection';
import { LayoutTabsWithImage } from './Layout/TabsWithImage';
import { CompanyOurMission } from './Company/OurMission';
import { Imprint } from './Imprint';
import { FreeText } from './FreeText';
import { TitleWithSubtitle } from './TitleWithSubtitle';
import { LayoutFeaturesRowWithImage } from './Layout/FeaturesRowWithImage';
import { LayoutTitleWithCards } from './Layout/TitleWithCards';
import { LayoutPricingSection } from './Layout/PricingSection';

const ContentfulModules: Record<string, React.FC> = {
  layoutHero: LayoutHero as React.FC,
  layoutFeatureSectionWithText: LayoutFeatureSectionWithText as React.FC,
  layoutBoxSectionWithLinksAndButton: LayoutBoxSectionWithLinksAndButton as React.FC,
  layoutSectionWithImageAndToggles: LayoutSectionWithImageAndToggles as React.FC,
  layoutTestimonialSection: LayoutTestimonialSection as React.FC,
  layoutCtaSection: LayoutCTASection as React.FC,
  layoutFaqSection: LayoutFAQSection as React.FC,
  layoutTwoFeatureSectionWithImage: LayoutFeatureSectionWithImage as React.FC,
  layoutTwoFeatureSectionWithBoxes: LayoutFeatureSectionWithBoxes as React.FC,
  layoutBoxSectionWithIcons: LayoutBoxSectionWithIcons as React.FC,
  companyOurMission: CompanyOurMission as React.FC,
  layoutImageWithTextAndHeading: LayoutImageWithTextAndHeading as React.FC,
  layoutNewsletterSection: LayoutNewsletterSection as React.FC,
  layoutTabsWithImage: LayoutTabsWithImage as React.FC,
  imprint: Imprint as React.FC,
  freeText: FreeText as React.FC,
  titleWithSubtitle: TitleWithSubtitle as React.FC,
  layoutFeaturesRowWithImage: LayoutFeaturesRowWithImage as React.FC,
  layoutTitleWithCards: LayoutTitleWithCards as React.FC,
  layoutPricingSection: LayoutPricingSection as React.FC,
};

export const getContentfulModule = (contentModuleId: string): React.FC => {
  const ComponentType: React.FC = ContentfulModules[
    contentModuleId
  ] as React.FC;

  return ComponentType;
};
