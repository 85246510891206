import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import {
  Section,
  Grid,
  H2WithBorder,
  H3WithBorder,
  Image,
  Box,
  H5,
  Hidden,
} from '../../../components';
import { space, colors, fontWeights } from '../../../theme';

const StyledCenteredImage = styled(Box)`
  transform: translateY(-50%);
`;

export const LayoutTabsWithImage: React.FC<{
  title: string;
  image: any;
  tabs: {
    title: string;
    text: {
      text: string;
      childMarkdownRemark: {
        html: string;
      };
    };
  }[];
}> = ({ title, tabs, image }) => {
  return (
    <Section py={space['8xl']}>
      <Grid.Container>
        <Grid.Row align="center">
          <Grid.Col>
            <Box mb={space.xl}>
              <H2WithBorder
                fontWeight={fontWeights.semiBold}
                textAlign="center"
                title={title}
                textHtml=""
              />
            </Box>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Hidden xs sm>
            <Grid.Col md={6}>
              <Box
                height="100%"
                width="100%"
                backgroundColor={colors.secondaryDark005}
              >
                <StyledCenteredImage top="50%" position="relative">
                  <Image image={image} />
                </StyledCenteredImage>
              </Box>
            </Grid.Col>
          </Hidden>
          <Grid.Col md={6}>
            {tabs.map((tab: any, index: number) => (
              <Box
                mb={index + 1 < tabs.length ? space.md : 0}
                mt={index === 0 ? 0 : space.md}
                backgroundColor={colors.secondaryDark005}
                key={index}
                px={space.lg}
                py={space.md}
                style={{ overflow: 'hidden' }}
              >
                <Box>
                  <H3WithBorder
                    fontWeight={fontWeights.semiBold}
                    title={tab.title}
                    textHtml=""
                  />
                </Box>
                <Box>
                  <H5>
                    {tab.text &&
                      tab.text.childMarkdownRemark &&
                      tab.text.childMarkdownRemark.html && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: tab.text.childMarkdownRemark.html,
                          }}
                        />
                      )}
                  </H5>
                </Box>
              </Box>
            ))}
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Section>
  );
};

export const contentfulLayoutTabsWithImageFragment = graphql`
  fragment ContentfulLayoutTabsWithImage on ContentfulLayoutTabsWithImage {
    title
    image {
      file {
        url
      }
      title
      fluid(maxWidth: 2400, quality: 100) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
    tabs {
      title
      text {
        text
        childMarkdownRemark {
          html
        }
      }
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`;
