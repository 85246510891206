import { graphql } from 'gatsby';
import React from 'react';
import { getContentfulModule } from '../modules';

import Layout from '../layout/Layout';

const BasicPageLayout: React.FC<{ data: any; location: any }> = (props) => {
  const layout = props.data.contentfulLayout;
  const header = props.data.contentfulHeader;
  const footer = props.data.contentfulFooter;

  return (
    <Layout
      greyHeader={layout.greyHeader}
      header={header}
      footer={footer}
      location={props.location}
      seoTitle={layout.title}
      seoDescription={layout.description && layout.description.description}
      seoImage={layout.image}
    >
      {layout.contentModules &&
        layout.contentModules.map((contentModule: any, index: number) => {
          if (
            !contentModule ||
            !contentModule.sys ||
            !contentModule.sys.contentType ||
            !contentModule.sys.contentType.sys
          )
            return null;

          const ContentfulModule = getContentfulModule(
            contentModule.sys.contentType.sys.id,
          );

          if (!ContentfulModule) return null;

          return <ContentfulModule key={index} {...contentModule} />;
        })}
    </Layout>
  );
};

export const query = graphql`
  query Layout($locale: String, $slug: String) {
    contentfulHeader(node_locale: { eq: $locale }) {
      menuItems {
        menuTitle
        menuLink
        isButton
        menuItems {
          menuTitle
          menuLink
          isButton
        }
      }
    }
    contentfulFooter(node_locale: { eq: $locale }) {
      menuItems {
        menuTitle
        menuLink
      }
      legalMenuItems {
        menuTitle
        menuLink
      }
    }
    contentfulLayout(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      title
      description {
        description
      }
      image {
        fluid {
          src
        }
      }
      greyHeader
      contentModules {
        ...ContentfulLayoutHero
        ...ContentfulLayoutFeatureSectionWithText
        ...ContentfulLayoutBoxSectionWithLinksAndButton
        ...ContentfulLayoutSectionWithImageAndToggles
        ...ContentfulLayoutTestimonialSection
        ...ContentfulLayoutCtaSection
        ...ContentfulLayoutFaqSection
        ...ContentfulLayoutFeatureSectionWithBoxes
        ...ContentfulLayoutFeatureSectionWithImage
        ...ContentfulLayoutBoxSectionWithIcons
        ...ContentfulCompanyOurMission
        ...ContentfulLayoutImageWithTextAndHeading
        ...ContentfulLayoutNewsletterSection
        ...ContentfulLayoutTabsWithImage
        ...ContentfulImprint
        ...ContentfulFreeText
        ...ContentfulTitleWithSubtitle
        ...ContentfulLayoutFeaturesRowWithImage
        ...ContentfulLayoutTitleWithCards
        ...ContentfulLayoutPricingSection
      }
    }
  }
`;

export default BasicPageLayout;
