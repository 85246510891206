import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import {
  Section,
  Grid,
  Box,
  H2WithBorder,
  Icons,
  H3,
  Text,
  FlexCol,
  Hidden,
  Visible,
} from '../../../components';
import { space, fontWeights, colors } from '../../../theme';
import Slider from 'react-slick';

const StyledBGBox = styled(Box)`
  background-color: #f9fafc;
  height: 100%;
  text-align: center;
  padding: ${space.xxl};
  margin: 0 auto;
`;

const StyledIconBGBox = styled(FlexCol)`
  border-radius: 4px;
  height: 80px;
  width: 80px;
  align-items: center;
  justify-content: center;
`;

const StyledSliderFlexCol = styled(FlexCol)`
  display: flex !important;
`;

const Card: React.FC<{ card; style? }> = ({ card, style }) => {
  if (!card.icon || !card.icon.icon) return null;
  const IconComponent = Icons[card.icon.icon];
  if (!IconComponent) return null;

  return (
    <FlexCol my={space.xl} style={style}>
      <StyledBGBox maxWidth={{ xxl: 350 }}>
        <FlexCol alignItems="center" mb={space.xxl}>
          <StyledIconBGBox
            backgroundColor={colors[card.iconBgColor] || colors.primary}
          >
            <IconComponent color="white" size={48} />
          </StyledIconBGBox>
        </FlexCol>
        <Box mb={space.xxl}>
          <H3 fontWeight={fontWeights.semiBold}>{card.title}</H3>
        </Box>
        <Box>
          <Text>
            {card.text &&
              card.text.childMarkdownRemark &&
              card.text.childMarkdownRemark.html && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: card.text.childMarkdownRemark.html,
                  }}
                />
              )}
          </Text>
        </Box>
      </StyledBGBox>
    </FlexCol>
  );
};

export const LayoutTitleWithCards: React.FC<{
  title: string;
  text: {
    text: string;
    childMarkdownRemark: {
      html: string;
    };
  };
  cards: {
    title: string;
    text: {
      text: string;
      childMarkdownRemark: {
        html: string;
      };
    };
    icon: {
      icon: string;
    };
    iconBgColor: string;
  }[];
}> = ({ title, text, cards }) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
  };

  return (
    <Section py={space.xxxxl}>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col xs={12}>
            <Box mb={space.xxxxl}>
              <H2WithBorder
                textAlign="center"
                fontWeight={fontWeights.semiBold}
                title={title}
                textHtml={
                  text &&
                  text.childMarkdownRemark &&
                  text.childMarkdownRemark.html
                }
              />
            </Box>
          </Grid.Col>
        </Grid.Row>
        <Hidden xs sm md>
          <Grid.Row>
            {cards.map((card, index: number) => (
              <Grid.Col lg={4} key={index} style={{ display: 'flex' }}>
                <Card card={card} />
              </Grid.Col>
            ))}
          </Grid.Row>
        </Hidden>
        <Visible xs sm md>
          <Box>
            <Slider {...sliderSettings}>
              {cards.map((card, index: number) => (
                <StyledSliderFlexCol key={index}>
                  <Card card={card} style={{ height: '100%' }} />
                </StyledSliderFlexCol>
              ))}
            </Slider>
          </Box>
        </Visible>
      </Grid.Container>
    </Section>
  );
};

export const contentfulLayoutTitleWithCardsFragment = graphql`
  fragment ContentfulLayoutTitleWithCards on ContentfulLayoutTitleWithCards {
    title
    text {
      text
      childMarkdownRemark {
        html
      }
    }
    cards {
      title
      text {
        text
        childMarkdownRemark {
          html
        }
      }
      icon {
        icon
      }
      iconBgColor
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`;
