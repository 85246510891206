import { graphql } from 'gatsby';
import React from 'react';
import { Section, Grid, Box, H1, Image, Text, Hidden } from '../components';
import { space, fontWeights, colors } from '../theme';

export const Imprint: React.FC<{
  title: string;
  text: {
    text: string;
  };
  image: any;
  smallImage: any;
}> = ({ title, text, image, smallImage }) => {
  const textArray = text.text.split('\n');
  return (
    <Section py={space.xxxxl}>
      <Grid.Container>
        <Grid.Row align="center">
          <Grid.Col xs={12} md={4}>
            <Box mb={space.xxxl}>
              <H1 fontWeight={fontWeights.extraBold}>{title}</H1>
            </Box>
            <Box mb={space.sm} width={30}>
              <Image image={smallImage} />
            </Box>
            <Box>
              {textArray.map((text: string, index: number) => (
                <Box key={index}>
                  {text ? (
                    <Text color={colors.darkGrey}>{text}</Text>
                  ) : (
                    <Text>&nbsp;</Text>
                  )}
                </Box>
              ))}
            </Box>
          </Grid.Col>
          <Grid.Col xs={12} md={8}>
            <Hidden xs sm>
              <Image image={image} />
            </Hidden>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Section>
  );
};

export const contentfulImprintFragment = graphql`
  fragment ContentfulImprint on ContentfulImprint {
    title
    text {
      text
    }
    image {
      file {
        url
      }
      title
      fluid(maxWidth: 2400, quality: 100) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
    smallImage {
      file {
        url
      }
      title
      fluid(maxWidth: 2400, quality: 100) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`;
