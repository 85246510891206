import { graphql } from 'gatsby';
import React from 'react';
import {
  Section,
  Grid,
  Image,
  Box,
  H3WithBorder,
  H4,
  Text,
  Separator,
  FlexRow,
  FlexCol,
  Icons,
  Visible,
  Hidden,
} from '../../../components';
import { space, fontWeights, colors } from '../../../theme';

const FeatureWithIcon: React.FC<{
  feature: {
    title: string;
    text: { text: string };
    icon: { icon: string };
  };
}> = ({ feature: { title, text, icon } }) => {
  if (!icon || !icon.icon) return null;
  const IconComponent = Icons[icon.icon];
  if (!IconComponent) return null;

  return (
    <FlexRow mb={space.lg}>
      <FlexCol flexShrink={0} flexGrow={0} mr={space.xl}>
        <IconComponent />
      </FlexCol>
      <Box>
        <Box mb={space.md}>
          <H4 color={colors.darkestGrey} fontWeight={fontWeights.semiBold}>
            {title}
          </H4>
        </Box>
        <Box mb={space.lg}>
          <Text color={colors.darkestGrey}>{text && text.text}</Text>
        </Box>
        <Separator.Light />
      </Box>
    </FlexRow>
  );
};

export const LayoutFeaturesRowWithImage: React.FC<{
  title: string;
  image: any;
  featuresLeft: {
    title: string;
    text: {
      text: string;
    };
    icon: {
      icon: string;
    };
  }[];
  featureRight: {
    title: string;
    text: {
      text: string;
    };
    icon: {
      icon: string;
    };
  };
}> = ({ title, image, featuresLeft, featureRight }) => {
  return (
    <Section py={space['8xl']} backgroundColor={colors.secondaryDark005}>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col xl={5} md={6}>
            <Visible xs sm>
              <Box mb={space.xxl}>
                <Image image={image} />
              </Box>
            </Visible>
            <Box mb={space.xxl}>
              <H3WithBorder title={title} textHtml="" />
            </Box>
            {featuresLeft.map((feature: any, index: number) => (
              <FeatureWithIcon key={index} feature={feature} />
            ))}
          </Grid.Col>
          <Visible xl xxl>
            <Grid.Col xl={1} md={0}></Grid.Col>
          </Visible>
          <Grid.Col xl={4} md={6}>
            <FlexCol height="100%">
              <Hidden xs sm>
                <Box
                  width="100%"
                  mt={{ md: `-${space['8xl']}` }}
                  mb={space.xxl}
                >
                  <Image image={image} />
                </Box>
              </Hidden>
              <Box mt="auto">
                <FeatureWithIcon feature={featureRight} />
              </Box>
            </FlexCol>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Section>
  );
};

export const contentfulLayoutFeaturesRowWithImageFragment = graphql`
  fragment ContentfulLayoutFeaturesRowWithImage on ContentfulLayoutFeaturesRowWithImage {
    title
    image {
      file {
        url
      }
      title
      fluid(maxWidth: 2400, quality: 100) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
    featuresLeft {
      title
      text {
        text
      }
      icon {
        icon
      }
    }
    featureRight {
      title
      text {
        text
      }
      icon {
        icon
      }
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`;
